import logo from "./assets/images/logo-circle.png";
import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import warning from "./assets/images/warning.png";
import loader from "./assets/images/loading.gif";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  const [approved, setApproved] = useState(false);
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const recaptchaRef = useRef();

  const handleOnTokenRequest = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${
          process.env.NODE_ENV === "development" ? "http://localhost:8888" : ""
        }/.netlify/functions/mint?address=${address}`
      );
      if (response.status === 200) {
        toast("Success", {
          type: "success",
        });
      } else {
        const txt = await response.text();
        toast(txt || "Failed to mint test tokens", {
          type: "error",
        });
      }
      setLoading(false);
      setAddress("");
      recaptchaRef.current.reset();
    } catch (err) {
      setLoading(false);
      toast("Failed to mint test tokens", {
        type: "error",
      });
    }
  };

  const isAddress = () => {
    return /^(0x){1}[0-9a-fA-F]{40}$/i.test(address);
  };

  return (
    <div className="app">
      <ToastContainer autoClose={2000} />
      <div className="card">
        <div className="header">
          <div className="logo">
            <img src={logo} width={60} height={60} />
          </div>
          <div className="meta">
            <div className="title">Kyte.One</div>
            <div className="sub-title">Kyte Fuji Testnet Faucet</div>
          </div>
        </div>

        <div>
          <label className="input-label">
            <input
              className="form-control-up"
              name="address"
              type="text"
              autoComplete="off"
              value={address}
              onChange={(event) => setAddress(event.target.value)}
              placeholder=" "
            />
            <span className="placeholder-text">C-chain Address</span>
          </label>
          <div style={{ marginTop: 20, marginBottom: 15 }}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LdJIMEbAAAAACgqlTRoYEU4CGCxR3IHQMI0GbHW"
              onChange={(token) => {
                if (token) setApproved(true);
                else setApproved(false);
              }}
            />
          </div>
          <div className="button-wrapper">
            {loading && (
              <div className="loader">
                <img src={loader} width={30} height={30} />
              </div>
            )}
            <button
              disabled={!approved || !isAddress() || loading}
              className={`button ${loading ? "loading" : ""}`}
              onClick={handleOnTokenRequest}
            >
              Request 30 KTE
            </button>
          </div>
        </div>

        <div className="warning">
          <img src={warning} width={16} height={16}></img>
          <span>Funds are not real.</span>
        </div>
      </div>
    </div>
  );
}

export default App;
